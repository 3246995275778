import React from "react"
import './Nibrastalk1.css'
import nib1 from '../img/nib1.JPG'
import nib2 from '../img/nib2.JPG'
// event 1 images
import eps11 from '../img/eps1.1.JPG'
import eps12 from '../img/eps1.2.JPG'
import eps13 from '../img/eps1.3.JPG'
import eps14 from '../img/eps1.4.JPG'
import eps15 from '../img/eps1.5.JPG'
// event 2 images
import eps21 from '../img/eps2/eps2.1.JPG'
import eps22 from '../img/eps2/eps2.2.JPG'
import eps23 from '../img/eps2/eps2.3.JPG'
import eps24 from '../img/eps2/eps2.4.JPG'
import eps25 from '../img/eps2/eps2.5.JPG'
// // event 3 images
import eps31 from '../img/eps3/eps3.1.jpg'
import eps32 from '../img/eps3/eps3.2.jpg'
import eps33 from '../img/eps3/eps3.3.jpg'
import eps34 from '../img/eps3/eps3.4.jpg'
import eps35 from '../img/eps3/eps3.5.jpg'

import { useState, useEffect } from "react"


const Nibrastalk1 = () =>{
const [index, setIndex] = useState(0);

const images = [eps11, eps12, eps13, eps14, eps15];
const images2 = [eps21, eps22, eps23, eps24, eps25];
const images3 = [eps31, eps32, eps33, eps34, eps35];
    useEffect(() => {
        const intervalId = setInterval(() => {
        // setIndex(index === 0 ? 1 : 0);
        setIndex((index + 1) % images.length);  
        setIndex((index + 1) % images2.length);
        setIndex((index + 1) % images3.length); 
        }, 3000);

        return () => {
        clearInterval(intervalId);
        };
    }, [index]);

    return(
        <>
        <div>
        <img className="img-face" src={nib1} />
        </div>
            <div className="container">
                <section className="first">
                    <div className="imagee">
                    <img className="img-contain" src={images[index]} />
                    </div>
                        <div className="text-area">
                            <h2>EPISODE 1:</h2>
                            <br/>
                            <p>
                           <strong>Date:</strong>  22nd August 2022 <br/><br />
                           <strong>Venue:</strong>  R&R, Dar Al Hikmah Library <br/><br />
                           <strong>Topic:</strong>  Nationalism/Patriotism and whether they are dividing or unifying the islamic Ummah.<br /> <br /> 
                            We were delighted to receive more than 50 attendees for our first episode, who expressed their opinions and thoughts on this topic. 
                            NIBRAS highly values everyone’s
                             attendance and participation and wishing to see you again shortly <br /><br />
                             <hr />
                            <span>Nibras.. where knowledge is shared</span>
                               </p>
                        </div>

                </section>

                <section className="first">
                    
                        <div className="text-area">
                            <h2>EPISODE 2:</h2>
                            <br/>
                            <p>  
                            <strong>Date:</strong>  3rd October 2022 <br/><br />
                           <strong>Venue:</strong>  R&R, Dar Al Hikmah Library <br/><br />
                           <strong>Topic:</strong>   Student Activism<br /> <br /> 
                           Praise be to Allah, we are delighted to report that our second intellectual gathering, held in collaboration with InUnity, was a resounding triumph. <br /> 
                           Our audience exceeded 60 attendees, and we were pleased to observe their enthusiastic involvement and active participation. <br /> <br />  
                           We look forward to offering more opportunities in the near future for you to share your insights and expertise, and we hope to see you there!<br /> <br /> 
                            <hr />
                            <span>Nibras.. where knowledge is shared</span>
                               </p>
                        </div>
                        <div className="imagee">
                    <img className="img-contain" src={images2[index]} />
                    </div>

                </section>

                <section className="first">
                    <div className="imagee">
                    <img className="img-contain" src={images3[index]} />
                    </div>
                        <div className="text-area">
                            <h2>EPISODE 3: </h2>
                            <br/>
            
                            <p>   
                           <strong>Date:</strong>   23rd December 2022 <br/><br />
                           <strong>Venue:</strong>  R&R, Dar Al Hikmah Library <br/><br />
                           <strong>Topic:</strong>   Feminism <br /> <br /> 
                           the third episode of NIBRAS talk took place on December 23rd and was a resounding success.<br /> 
                            We were thrilled to have a record-breaking turnout for this episode, during which we were joined by Dr. Syaza Shukri, one of our esteemed lecturers at IIUM.<br /> <br />  
                            The enthusiastic participation of our audience reaffirmed our belief that people are eager to engage in discussions about issues that impact our society and the world at large. <br /> <br />
                            We are excited to produce more episodes and other events in the future, and we hope that you will join us for these endeavors.<br /> <br /> 
                            <hr />
                            <span>Nibras.. where knowledge is shared</span>
                               </p>
                        </div>

                </section>
            </div>
        </>
    )
}

export default Nibrastalk1